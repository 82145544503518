<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->

							<article class="article-body" id="article-body" rel="image-enlarge">
								<p data-identifyelement="463">
									<strong data-identifyelement="464">Please Note!</strong> There are 2 versions of IPTV-Smarters, one for iOS and one for Android. The iOS version
									will not work (as of now)
								</p>
								<p data-identifyelement="463"><br /></p>
								<p>
									<strong
										><span style="background-color: rgb(247, 218, 100)"
											><img
												src="../../../assets/images/pEb6xYBQ963uGq3b0vp2Kkjy-mizAIzx_g.png?1634703487"
												style="width: 57px"
												class="fr-fil fr-dii"
												data-attachment="[object Object]"
												data-id="48150281887" /></span
									></strong>
								</p>
								<p>
									<span style="background-color: rgb(247, 218, 100)"
										><strong><br /></strong
									></span>
								</p>
								<p>
									<span style="background-color: rgb(247, 218, 100)"
										><strong>Before you begin, please note that the Smarters app will&nbsp;</strong
										><strong
											><u dir="ltr"><strong>NOT</strong></u></strong
										></span
									><strong><span style="background-color: rgb(247, 218, 100)">&nbsp;have a working EPG TV guide</span></strong
									><span style="background-color: rgb(247, 218, 100)">.</span>
								</p>
								<p dir="ltr">
									Smarters will <strong><u>not</u></strong> show movie or Tv show posters.
								</p>
								<p><br /></p>
								<p><br /></p>
								<p dir="ltr">
									We highly recommend using Tivimate app instead for live tv
									<router-link :to="{ name: 'tivimateInstallationInstructions' }" class="text-blue-400"
										><strong>Tivimate installation instructions</strong></router-link
									>
								</p>
								<p dir="ltr"><br /></p>
								<p dir="ltr">
									For an all in one app Live TV, Movies and TV shows we recommend using {{ $store.state.brandInfo.displayName }} app or
									{{ $store.state.brandInfo.appName }} app.
									<a :href="$store.state.brandInfo.faqLink">{{ $store.state.brandInfo.faqLink }}</a>
								</p>
								<p data-identifyelement="465"><br /></p>
								<p data-identifyelement="465"><br /></p>
								<p data-identifyelement="465" dir="ltr"><strong>After</strong> reading the above information,</p>
								<div data-identifyelement="467" data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true">
									<p data-identifyelement="468">
										Download and install IPTV-Smarters from the play store.<br data-identifyelement="469" /><br data-identifyelement="470" />
									</p>
									<p data-identifyelement="471">Open the app and accept the terms.<br data-identifyelement="472" /><br data-identifyelement="473" /></p>
									<p data-identifyelement="474">Click on “ADD NEW USER”<br data-identifyelement="475" /><br data-identifyelement="476" /></p>
									<p data-identifyelement="477">
										Choose “<strong>Load Your Playlist Or File/URL</strong>” (first top left option)<br data-identifyelement="478" /><img
											src="../../../assets/images/4GLtWTTudtmtYPcQSwDFGm6y6xAvjFhpEw.png?1593283435"
											style="width: auto"
											class="fr-fic fr-fil fr-dib"
											data-attachment="[object Object]"
											data-id="48046577321"
										/><br data-identifyelement="481" /><br data-identifyelement="482" />*IF YOU DON’T HAVE THESE TWO OPTIONS TO CHOOSE AND YOU SEE ONLY
										SCREEN<br data-identifyelement="483" />WITH “ENTER YOUR LOGIN DETAILS” then this version of IPTV-Smarters WILL NOT work.<br
											data-identifyelement="484"
										/><br data-identifyelement="485" />
									</p>
									<p data-identifyelement="486">
										Click “Allow” to access file.<br data-identifyelement="487" /><img
											src="../../../assets/images/LcMAr9djdOnH3psLrr3upH95HHe5HnlBYg.png?1593283626"
											class="fr-fic fr-fil fr-dib"
											data-attachment="[object Object]"
											data-id="48046577376"
										/><br data-identifyelement="490" /><br data-identifyelement="491" />
									</p>
									<p data-identifyelement="492">
										Give a playlist name {{ $store.state.brandInfo.appName }} <br data-identifyelement="493" /><br data-identifyelement="494" />
									</p>
									<p data-identifyelement="495">
										Choose “M3U URL” as Playlist Type, and in the URL put in the info:<br data-identifyelement="496" />Syntax:<br
											data-identifyelement="497"
										/><br data-identifyelement="498" />
									</p>
									<strong data-identifyelement="499">Channels list and recent VOD</strong><br data-identifyelement="500" /><a
										data-identifyelement="501"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD`"
										rel="noopener noreferrer"
										target="_blank"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong data-identifyelement="502"
											><span data-identifyelement="503" style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong data-identifyelement="504"><span data-identifyelement="505" style="color: rgb(0, 0, 0)">PASSWORD</span></strong></a
									>
									<p data-identifyelement="507">
										<br data-identifyelement="509" /><br data-identifyelement="510" />Double check that the info you’ve put is correct then click “Add User”<br
											data-identifyelement="511"
										/><br data-identifyelement="512" />
									</p>
									<p data-identifyelement="513">
										On the next screen click on {{ $store.state.brandInfo.displayName }} <br data-identifyelement="514" /><br data-identifyelement="515" />
									</p>
									<p data-identifyelement="516">Then you can click on LiveTV/Movies/Series and after you click that, you’ll need to click on “Explore ALL”</p>
								</div>
								<p data-identifyelement="517"><br data-identifyelement="518" /></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'howDoIInstalGroupTvOnIptvSmarters',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
